<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="封面" prop="image" >
        <file-upload v-model="form.image" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="用戶ID" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用戶ID" />
      </a-form-model-item>
      <a-form-model-item label="观看总人数" prop="totalNumber" >
        <a-input v-model="form.totalNumber" placeholder="请输入观看总人数" />
      </a-form-model-item>
      <a-form-model-item label="直播狀態 " prop="directStatus" >
      </a-form-model-item>
      <a-form-model-item label="是否前置摄像头" prop="isFront" >
        <a-input v-model="form.isFront" placeholder="请输入是否前置摄像头" />
      </a-form-model-item>
      <a-form-model-item label="是否打开麦克风" prop="microphone" >
        <a-input v-model="form.microphone" placeholder="请输入是否打开麦克风" />
      </a-form-model-item>
      <a-form-model-item label="是否打开摄像头" prop="camera" >
        <a-input v-model="form.camera" placeholder="请输入是否打开摄像头" />
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="createBy" >
        <a-input v-model="form.createBy" placeholder="请输入创建人" />
      </a-form-model-item>
      <a-form-model-item label="编辑人" prop="updateBy" >
        <a-input v-model="form.updateBy" placeholder="请输入编辑人" />
      </a-form-model-item>
      <a-form-model-item label="刪除狀態(0-正常,1-已刪除)" prop="delFlag" >
        <a-input v-model="form.delFlag" placeholder="请输入刪除狀態(0-正常,1-已刪除)" />
      </a-form-model-item>
      <a-form-model-item label="直播时长" prop="closeTime" >
        <a-input v-model="form.closeTime" placeholder="请输入直播时长" />
      </a-form-model-item>
      <a-form-model-item label="是否打开美颜 0-否 1-是" prop="isBeauty" >
        <a-input v-model="form.isBeauty" placeholder="请输入是否打开美颜 0-否 1-是" />
      </a-form-model-item>
      <a-form-model-item label="PK狀態 0-未开始 1-准备中 2-pk中 3-pk成功 4-pk失败 5-pk平局" prop="pkStatus" >
      </a-form-model-item>
      <a-form-model-item label="新增粉丝数量" prop="newFans" >
        <a-input v-model="form.newFans" placeholder="请输入新增粉丝数量" />
      </a-form-model-item>
      <a-form-model-item label="獲贊數量" prop="likedNum" >
        <a-input v-model="form.likedNum" placeholder="请输入獲贊數量" />
      </a-form-model-item>
      <a-form-model-item label="禮物数量" prop="giftNum" >
        <a-input v-model="form.giftNum" placeholder="请输入禮物数量" />
      </a-form-model-item>
      <a-form-model-item label="显示ID" prop="showId" >
        <a-input v-model="form.showId" placeholder="请输入显示ID" />
      </a-form-model-item>
      <a-form-model-item label="PK进行中,另外一个直播间的id" prop="directId" >
        <a-input v-model="form.directId" placeholder="请输入PK进行中,另外一个直播间的id" />
      </a-form-model-item>
      <a-form-model-item label="PK进行中的id" prop="directPkId" >
        <a-input v-model="form.directPkId" placeholder="请输入PK进行中的id" />
      </a-form-model-item>
      <a-form-model-item label="录制視頻封面" prop="recordVideoCover" >
        <a-input v-model="form.recordVideoCover" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="录制視頻路径" prop="recordVideo" >
        <a-input v-model="form.recordVideo" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="录制視頻时长" prop="recordVideoTime" >
        <a-input v-model="form.recordVideoTime" placeholder="请输入录制視頻时长" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDirectSeeding, addDirectSeeding, updateDirectSeeding } from '@/api/platform/directSeeding'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        image: null,

        userId: null,

        totalNumber: null,

        directStatus: 0,

        isFront: null,

        microphone: null,

        camera: null,

        createTime: null,

        createBy: null,

        updateBy: null,

        delFlag: null,

        closeTime: null,

        isBeauty: null,

        pkStatus: 0,

        newFans: null,

        likedNum: null,

        giftNum: null,

        showId: null,

        directId: null,

        directPkId: null,

        recordVideoCover: null,

        recordVideo: null,

        recordVideoTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        image: null,
        userId: null,
        totalNumber: null,
        directStatus: 0,
        isFront: null,
        microphone: null,
        camera: null,
        createTime: null,
        createBy: null,
        updateBy: null,
        delFlag: null,
        closeTime: null,
        isBeauty: null,
        pkStatus: 0,
        newFans: null,
        likedNum: null,
        giftNum: null,
        showId: null,
        directId: null,
        directPkId: null,
        recordVideoCover: null,
        recordVideo: null,
        recordVideoTime: null
      }
    /*  this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDirectSeeding({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDirectSeeding(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDirectSeeding(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
