import request from '@/utils/request'


// 查询直播记录分页
export function pageDirectSeeding(query) {
  return request({
    url: '/platform/direct-seeding/page',
    method: 'get',
    params: query
  })
}

// 关闭直播
export function closeSeeding(data) {
  return request({
    url: '/platform/direct-seeding/close',
    method: 'get',
    params: data
  })
}
